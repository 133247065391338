<template>
  <div>
    <transition name="slide-marker">
      <v-card style="position: absolute;
    height: 5vh;
    padding: 0.5vh 0 0.5vh 1vw;
    border-bottom-right-radius: 16px;
    border-top-right-radius: 16px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    background-color: grey !important;
    color: grey !important;
    width: 3vw;
    z-index: 2;
    top: 1vh;
    left: 0vw;" v-if="!viewTaskInfo">
        <v-btn icon plain @click="viewTaskInfo = true">
          <v-icon>info</v-icon>
        </v-btn>
      </v-card>
    </transition>
    <v-card flat class="my-0 py-0" style="height: 100vh">
      <v-toolbar flat color="transparent">
        <v-toolbar-title class="ml-15">
          Manual Inspection <v-chip class="ml-2" :color="getTaskStatus(task).color" outlined small><v-icon small left>{{
            getTaskStatus(task).icon
          }}</v-icon>
            {{ task.status }}</v-chip>

        </v-toolbar-title>
        <v-spacer></v-spacer>
        <div v-if="!$vuetify.breakpoint.mobile" :key="userUpdateKey" class="mr-2">
          <v-tooltip top v-for="user in activeUsers" :key="user.connectionId">
            <template v-slot:activator="{ on, attrs }">
              <v-avatar v-bind="attrs" v-on="on" style="cursor: pointer" color="secondary" size="48"
                class="mt-0 pt-0 mx-1">
                <span v-if="!user.user.avatar" class="primaryText--text text-h5">{{$Format.avatarAbbreviation(user.user.name)
                }}</span>
                <img v-else :src="user.user.avatar" referrerpolicy="no-referrer" />
              </v-avatar>
            </template>
            <span style="font-size: 12px">{{ user.user.name }}</span>
          </v-tooltip>
        </div>

        <div :key="inspectionResultKey" v-if="inspectionResult && canSubmit == inspectionResult.id">
          <v-btn small rounded color="green" v-if="task.status == 'In Progress' && !passValidation && !loading"
            @click="markAll" :loading="loadingMarkAll">
            <v-icon left>check</v-icon> Mark All
          </v-btn>

          <v-btn v-if="!viewExportCertificateInfo" text color="blue" class="my-0 py-0"
            @click="viewExportCertificateInfo = !viewExportCertificateInfo">
            <v-icon>article</v-icon> View Export Certificate
          </v-btn>

          <v-btn :key="validationKey" v-if="task.status == 'In Progress' && validateInspection" text color="primary"
            @click="confirmDialog = true" :loading="savingInspection">Submit Inspection</v-btn>
          <v-btn v-else-if="task.status == 'In Progress'" text color="primary"
            @click="$emit('close'), inspectionHistory = [], inspectionResult = {}" :loading="savingInspection">Save
            Inspection</v-btn>
          <v-btn v-else-if="savingInspection" text color="primary"
            @click="$emit('close'), inspectionHistory = [], inspectionResult = {}" :loading="savingInspection">Save
            Inspection</v-btn>
        </div>
        <v-btn v-if="task.status == 'New' || task.status == 'Resubmitted'" text color="primary"
          @click="startTask(task)">Start Inspection</v-btn>
        <v-btn v-if="task.status == 'Approved'" text color="primary" @click="revokeInspection">Revoke Status</v-btn>
        <v-btn text @click="closeAddendumDialog()">X</v-btn>
      </v-toolbar>
      <v-card-text class="my-0 py-0">
        <v-row class="no-gutters no-wrap">
          <transition name="slide-nav">
            <v-col cols="12" sm="2" style="height: 90vh" v-if="viewTaskInfo">
              <v-card flat outlined style="height: 100%">
                <v-card-title>Task: #{{ task.id }} <v-spacer></v-spacer>
                  <v-btn text @click="viewTaskInfo = false"><v-icon>chevron_left</v-icon></v-btn>
                </v-card-title>
                <v-card-text>
                  <v-tabs grow flat v-model="tab">
                    <v-tabs-slider></v-tabs-slider>
                    <v-tab>Detail</v-tab>
                    <v-tab>Attachments <v-chip small v-if="taskAttachments.length > 0" color="blue" class="ml-1">{{
                      taskAttachments.length }}</v-chip></v-tab>
                  </v-tabs>
                  <div v-if="tab == 0">
                    <v-list dense style="max-height: 75vh; overflow-y: auto">
                      <v-list-item v-if="task.submittedForInspectionBy">
                        <v-list-item-action>
                          <v-icon>person</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title v-if="task.submittedForInspectionBy">
                            {{ task.submittedForInspectionBy.firstName }} {{ task.submittedForInspectionBy.surname }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Requested inspection
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-if="task.startedBy">
                        <v-list-item-action>
                          <v-icon>local_police</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ task.startedBy.firstName }} {{ task.startedBy.surname }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Inspection started
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-if="inspectionResult.inspectedBy">
                        <v-list-item-action>
                          <v-icon>local_police</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ inspectionResult.inspectedBy.firstName }} {{ inspectionResult.inspectedBy.surname }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Inspector
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-if="inspectionResult.end">
                        <v-list-item-action>
                          <v-icon>schedule</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ formatDate(inspectionResult.end) }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Inspection Date
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider v-if="inspectionResult.comments"></v-divider>

                      <v-subheader style="font-size: 16px" v-if="inspectionResult.comments">Comments</v-subheader>
                      <v-list-item v-if="inspectionResult.comments">
                        <v-list-item-content>
                          {{ inspectionResult.comments }}
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider v-if="inspectionResult.comments"></v-divider>

                      <v-subheader v-if="inspectionHistory && inspectionHistory.length > 0" style="font-size: 16px">
                        Inspection History
                      </v-subheader>
                      <v-list-item v-for="history in inspectionHistory" :key="history.id"
                        @dblclick="viewInspectionResult(history)"
                        :style="{ 'border-left': inspectionResult && inspectionResult.id == history.id ? '4px solid var(--v-primary-base)' : '' }">
                        <v-list-item-action>
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-icon class="mr-2" v-on="on" :color="getTaskStatus(history).color">{{
                                getTaskStatus(history).icon
                              }}</v-icon>
                            </template>
                            <span>{{ getTaskStatus(history).name }}</span> </v-tooltip>
                        </v-list-item-action>
                        <v-list-item-content>

                          <v-list-item-title class="text-wrap">
                            {{ formatDate(history.start) }} - {{ formatDate(history.end) }}
                          </v-list-item-title>
                          <v-list-item-subtitle v-if="history.startedBy">
                            {{ history.startedBy.firstName }} {{ history.startedBy.surname }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle v-if="history.comments" style="font-size: 12px;color:orange"
                            class="text-wrap">
                            <v-icon small class="mr-1">description</v-icon> {{ history.comments }}
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-icon small class="mr-2" @click="openEditDialog(history)">edit</v-icon>
                        </v-list-item-action>
                      </v-list-item>

                      <v-subheader
                        v-if="inspectionResult && inspectionResult.taskInspectionResultFields && inspectionResult.taskInspectionResultFields.length > 0">
                        Inspection Log
                      </v-subheader>
                      <v-list-item v-for="log in inspectionResult.taskInspectionResultFields" :key="log.id">
                        <v-list-item-action>
                          <v-icon :color="fieldStatusColor(log.field)">{{ fieldStatusIcon(log.field) }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ fieldFriendlyName(log.field) }}
                          </v-list-item-title>
                          <v-list-item-subtitle v-if="log.inspectedBy">
                            {{ log.inspectedBy.firstName }} {{ log.inspectedBy.surname }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            {{ formatDate(log.time) }}
                          </v-list-item-subtitle>
                        </v-list-item-content>

                      </v-list-item>

                    </v-list>
                  </div>
                  <div v-else-if="tab == 1">
                    <v-list dense style="max-height: 50vh; min-height: 10vh; overflow-y: auto;" :key="taskAttachmentKey">
                      <v-list-item v-for="doc in taskAttachments" :key="doc.id">
                        <v-list-item-action class="ml-0 pl-0" style="width: 20px">
                          <v-img v-if="getDocumentIcon(doc.shipmentDocument.name)" class="mr-2"
                            style="height: 30px; width: 30px" :src="getDocumentIcon(doc.shipmentDocument.name)">
                          </v-img>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ doc.shipmentDocument.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ formatDate(doc.shipmentDocument.createdAt) }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action :key="documentKey">
                          <v-row :key="documentKey">
                            <v-btn :key="documentKey" :loading="docLoading == doc.id" icon
                              v-if="doc.file && (doc.shipmentDocument.name.toLowerCase()).includes('.pdf')" small
                              @click="previewDocument(doc)"><v-icon small>visibility</v-icon></v-btn>
                            <v-btn v-if="doc.shipmentDocument.key" icon :loading="docDownloading == doc.id"
                              @click="downloadDocument(doc)" small><v-icon small>download</v-icon></v-btn>
                          </v-row>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item v-if="taskAttachments.length == 0
                        ">
                        <v-list-item-content class="text-center">
                          <span style="color: grey">No attachments.</span>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    <v-btn style="width:100%" @click="uploadModal = true">
                      <v-icon>upload</v-icon> Upload Attachment
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </transition>
          <v-expand-transition>
            <v-col cols="12"
              :sm="viewExportCertificateInfo && viewTaskInfo ? 7 : viewExportCertificateInfo ? 9 : viewTaskInfo ? 10 : 12"
              :md="viewExportCertificateInfo && viewTaskInfo ? 8 : viewExportCertificateInfo ? 9 : viewTaskInfo ? 10 : 12"
              class="grow my-0 py-0">
              <v-card flat v-if="addendum && !loading" class="my-0 py-0">
                <v-card-text class="mb-0 pb-0">
                  <v-row>
                    <v-col cols="12" sm="4">
                      <v-text-field readonly disabled solo dense v-model="addendum.agentName" persistent-hint
                        hint="Name of Forwarding Agent"></v-text-field>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-text-field readonly disabled solo dense v-model="addendum.portOfLoadCode" persistent-hint
                            hint="Port of Load">
                            <template v-slot:append v-if="task.status != 'New'">
                              <v-btn icon :key="validationKey" :color="fieldStatusColor('portOfLoadCode')"
                                :loading="loadingField == 'portOfLoadCode'"
                                @click="changeStatus('portOfLoadCode')"><v-icon>{{
                                  fieldStatusIcon('portOfLoadCode')
                                }}</v-icon></v-btn>
                            </template>
                          </v-text-field>
                          <v-text-field readonly disabled solo dense v-model="addendum.portOfDischargeCode"
                            persistent-hint hint="Port of Entry">
                            <template v-slot:append v-if="task.status != 'New'">
                              <v-btn icon :key="validationKey" :color="fieldStatusColor('portOfDischargeCode')"
                                :loading="loadingField == 'portOfDischargeCode'"
                                @click="changeStatus('portOfDischargeCode')"><v-icon>{{
                                  fieldStatusIcon('portOfDischargeCode')
                                }}</v-icon></v-btn>
                            </template></v-text-field>

                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field readonly disabled solo dense v-model="addendum.targetCountry" persistent-hint
                            hint="Target Country">
                            <template v-slot:append v-if="task.status != 'New'">
                              <v-btn icon :key="validationKey" :color="fieldStatusColor('targetCountry')"
                                :loading="loadingField == 'targetCountry'"
                                @click="changeStatus('targetCountry')"><v-icon>{{
                                  fieldStatusIcon('targetCountry')
                                }}</v-icon></v-btn>
                            </template>
                          </v-text-field>
                          <v-text-field readonly disabled solo dense v-model="addendum.targetRegion" persistent-hint
                            hint="Target Region">
                            <template v-slot:append v-if="task.status != 'New'">
                              <v-btn icon :key="validationKey" :color="fieldStatusColor('targetRegion')"
                                :loading="loadingField == 'targetRegion'" @click="changeStatus('targetRegion')"><v-icon>{{
                                  fieldStatusIcon('targetRegion')
                                }}</v-icon></v-btn>
                            </template></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field readonly disabled solo dense v-model="addendum.exporterName" persistent-hint
                        hint="Name of Exporter"> <template v-slot:append v-if="task.status != 'New'">
                          <v-btn icon :key="validationKey" :color="fieldStatusColor('exporterName')"
                            :loading="loadingField == 'exporterName'" @click="changeStatus('exporterName')"><v-icon>{{
                              fieldStatusIcon('exporterName')
                            }}</v-icon></v-btn>
                        </template></v-text-field>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-text-field readonly disabled solo dense v-model="inspectionResult.reference" persistent-hint
                            hint="Export Certificate number"></v-text-field>
                          <v-text-field readonly disabled solo dense v-model="addendum.carrierReferenceNumber"
                            persistent-hint hint="Booking Ref"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field readonly disabled solo dense v-model="addendum.vesselName" persistent-hint
                            hint="Vessel">
                            <template v-slot:append v-if="task.status != 'New'">
                              <v-btn icon :key="validationKey" :color="fieldStatusColor('vesselName')"
                                :loading="loadingField == 'vesselName'" @click="changeStatus('vesselName')"><v-icon>{{
                                  fieldStatusIcon('vesselName')
                                }}</v-icon></v-btn>
                            </template></v-text-field>
                          <v-text-field readonly disabled solo dense v-model="addendum.voyageNumber" persistent-hint
                            hint="Voyage number">
                            <template v-slot:append v-if="task.status != 'New'">
                              <v-btn icon :key="validationKey" :color="fieldStatusColor('voyageNumber')"
                                :loading="loadingField == 'voyageNumber'" @click="changeStatus('voyageNumber')"><v-icon>{{
                                  fieldStatusIcon('voyageNumber')
                                }}</v-icon></v-btn>
                            </template></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="12" sm="4">

                      <v-row align="center">
                        <v-col cols="12" sm="6" class="mb-0 pb-0">
                          <v-text-field readonly disabled solo dense v-model="addendum.today" persistent-hint
                            hint="Date"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" class="mb-0 pb-0 mt-0 pt-0">
                          <v-btn class="mt-0 pt-0" @click="notesModal = true" v-if="task && task.notes" outlined
                            color="blue"><v-icon left>description</v-icon>View Notes</v-btn>
                        </v-col>
                        <v-col cols="12" sm="6" class="mt-0 pt-0">
                          <v-text-field readonly disabled solo dense v-model="addendum.regimeCode" persistent-hint
                            hint="Regime Code">
                            <template v-slot:append v-if="task.status != 'New'">
                              <v-btn icon :key="validationKey" :color="fieldStatusColor('regimeCode')"
                                :loading="loadingField == 'regimeCode'" @click="changeStatus('regimeCode')"><v-icon>{{
                                  fieldStatusIcon('regimeCode')
                                }}</v-icon></v-btn>
                            </template>
                          </v-text-field>
                          <v-text-field readonly disabled solo dense v-model="addendum.etd" persistent-hint
                            hint="Departure Date">
                            <template v-slot:append v-if="task.status != 'New'">
                              <v-btn icon :key="validationKey" :color="fieldStatusColor('etd')"
                                :loading="loadingField == 'etd'" @click="changeStatus('etd')"><v-icon>{{
                                  fieldStatusIcon('etd')
                                }}</v-icon></v-btn>
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" class="mt-0 pt-0">
                          <v-text-field readonly disabled hint="Name" persistent-hint solo dense
                            v-model="addendum.userFullName"> <template v-slot:append v-if="task.status != 'New'">
                              <v-btn icon :key="validationKey" :color="fieldStatusColor('userFullName')"
                                :loading="loadingField == 'userFullName'" @click="changeStatus('userFullName')"><v-icon>{{
                                  fieldStatusIcon('userFullName')
                                }}</v-icon></v-btn>
                            </template>
                          </v-text-field>
                          <v-card flat style="background-color: white !important; height: 50px; width: 100%">
                            <v-card-text class="my-0 py-0" style="border-radius: 0px">
                              <v-img v-if="addendum.userSignature" contain height="50"
                                :src="addendum.userSignature"></v-img>
                            </v-card-text>
                          </v-card>

                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card-text v-if="addendum">
                <v-progress-linear indeterminate color="primary" v-if="loading"></v-progress-linear>
                <v-row justify="end" v-if="addendum.sumOfCartons && !loading">
                  <v-col cols="12" sm="4" md="3">
                    <span style="font-size: 14px">
                      Total Cartons: <b> <u>{{ addendum.sumOfCartons }}</u> </b> <v-btn :key="validationKey"
                        v-if="task.status != 'New'" icon :color="fieldStatusColor('sumCartons')"
                        :loading="loadingField == 'sumCartons'" @click="changeStatus('sumCartons')"><v-icon>{{
                          fieldStatusIcon('sumCartons')
                        }}</v-icon></v-btn>
                    </span>
                  </v-col>
                  <v-col cols="12" sm="4" md="3">
                    <span style="font-size: 14px">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn @click="uniqueSSCCModal = true" v-on="on" color="blue" icon small><v-icon
                              small>launch</v-icon></v-btn>
                        </template>
                        <span>View Unique SSCC List</span>
                      </v-tooltip>
                      Total Pallets:

                      <b><u>{{ addendum.totalPallets }}</u></b> <v-btn :key="validationKey" v-if="task.status != 'New'"
                        icon :color="fieldStatusColor('totalPallets')" :loading="loadingField == 'totalPallets'"
                        @click="changeStatus('totalPallets')"><v-icon>{{
                          fieldStatusIcon('totalPallets')
                        }}</v-icon></v-btn>
                    </span>
                  </v-col>
                  <v-col cols="12" sm="4" md="3">
                    <span style="font-size: 14px">
                      Total Nett Weight: <b><u>{{ addendum.nettWeightSum }} KG</u></b> <v-btn :key="validationKey"
                        v-if="task.status != 'New'" icon :color="fieldStatusColor('nettWeightSum')"
                        :loading="loadingField == 'nettWeightSum'" @click="changeStatus('nettWeightSum')"><v-icon>{{
                          fieldStatusIcon('nettWeightSum')
                        }}</v-icon></v-btn>
                    </span>
                  </v-col>
                </v-row>
                <v-data-table v-if="addendum.pallet && !loading" height="60vh" :item-class="itemRowBackground"
                  :items="addendum.pallet" :headers="palletHeaders" fixed-header dense hide-default-footer
                  disable-pagination hide-default-header :key="tableKey">
                  <template #header="{ props: { headers } }">
                    <thead class="v-data-table-header">
                      <tr>
                        <th v-for="header in headers" :key="header.value" scope="col">
                          <v-row v-if="header.value === 'line'">
                            <v-col cols="12">
                              <v-tooltip location="top">
                                <!-- <template v-slot:activator="{ on }">
                                  <span v-on="on">{{ header.tooltip }}</span>
                                </template> -->
                                <span>{{ header.tooltip }}</span>
                              </v-tooltip>
                            </v-col>
                          </v-row>

                          <v-row v-if="header.value == 'inspectionDate'" align="center">
                            <v-col cols="12">
                              <span>Inspection Date</span>
                              <v-btn v-if="task.status != 'New'" :key="validationKey" icon
                                :color="fieldStatusColor('inspectionDate')" :loading="loadingField == 'inspectionDate'"
                                @click="changeStatus('inspectionDate')"><v-icon>{{
                                  fieldStatusIcon('inspectionDate')
                                }}</v-icon></v-btn>
                            </v-col>

                          </v-row>
                          <v-row v-else-if="header.value == 'stuffDate'" align="center">
                            <v-col cols="12">
                              <span>Stuff Date</span>
                              <v-btn v-if="task.status != 'New'" :key="validationKey" icon
                                :color="fieldStatusColor('stuffDate')" :loading="loadingField == 'stuffDate'"
                                @click="changeStatus('stuffDate')"><v-icon>{{
                                  fieldStatusIcon('stuffDate')
                                }}</v-icon></v-btn>
                            </v-col>

                          </v-row>
                          <v-row v-else-if="header.value == 'inspectionManifestNo'" align="center">
                            <v-col cols="12">
                              <span>Manifest No.</span>
                              <v-btn v-if="task.status != 'New'" :key="validationKey" icon
                                :color="fieldStatusColor('inspectionManifestNo')"
                                :loading="loadingField == 'inspectionManifestNo'"
                                @click="changeStatus('inspectionManifestNo')"><v-icon>{{
                                  fieldStatusIcon('inspectionManifestNo')
                                }}</v-icon></v-btn>
                            </v-col>
                          </v-row>

                          <v-row v-else-if="header.value == 'containerNo'" align="center">
                            <v-col cols="12">
                              <span>Container No.</span>
                              <v-btn v-if="task.status != 'New'" :key="validationKey" icon
                                :color="fieldStatusColor('containerNo')" :loading="loadingField == 'containerNo'"
                                @click="changeStatus('containerNo')"><v-icon>{{
                                  fieldStatusIcon('containerNo')
                                }}</v-icon></v-btn>
                            </v-col>

                          </v-row>
                          <span v-else>{{ header.text }}</span>
                        </th>
                      </tr>
                    </thead>
                  </template>

                  <template v-slot:[`item.gradeClassCode`]="{ item }">
                    <template v-if="item.gradeClassCode.includes(1)">
                      {{ 1 }}
                    </template>
                    <template v-else-if="item.gradeClassCode.includes(2)">
                      {{ 2 }}
                    </template>
                    <template v-else-if="item.gradeClassCode.includes('P')">
                      {{ 'P' }}
                    </template>
                  </template>
                  <template v-slot:[`item.line`]='{ item, index }'>
                    <template v-if="addendum.breakbulk">
                      {{ uniquePallets.indexOf(item.sscc) + 1 }}
                    </template>
                    <template v-else>
                      {{ index + 1 }}
                    </template>

                  </template>
                  <template v-slot:[`item.action`]="{ item }">
                    <!-- {{ index + 1 }} -->
                    <v-tooltip top v-if="item.audit">
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" v-if="task.status != 'New'" icon :color="fieldStatusColor(item.sscc)"
                          :loading="loadingField == item.sscc" @click="changeStatus(item.sscc)"><v-icon>{{
                            fieldStatusIcon(item.sscc)
                          }}</v-icon></v-btn>
                      </template>
                      <span>Change {{ item.sscc }} status</span>
                    </v-tooltip>
                  </template>

                  <template v-slot:[`item.attachments`]="{ item }">
                    <v-tooltip top v-if="item.consignmentNote">
                      <template v-slot:activator="{ on }">
                        <v-btn class="mx-1" outlined v-on="on" small icon @click="viewConsignmentNote(item)">
                          <v-icon small>description</v-icon>
                        </v-btn>
                      </template>
                      <span>Consignment Note</span>
                    </v-tooltip>

                    <v-tooltip top v-if="item.permitDocument">
                      <template v-slot:activator="{ on }">
                        <v-btn class="mx-1" outlined v-on="on" small icon @click="viewRemovalPermit(item)">
                          <v-icon small>agriculture</v-icon>
                        </v-btn>
                      </template>
                      <span>Removal Permit: {{ item.permitNo }} expires
                        {{ item.permitValidUntil }}</span>
                    </v-tooltip>
                    <v-tooltip top v-if="item.phytcleanCertificate">
                      <template v-slot:activator="{ on }">
                        <v-btn class="mx-1" outlined v-on="on" small icon @click="viewPhytcleanCertificate(item)">
                          <v-icon small>verified_user</v-icon>
                        </v-btn>
                      </template>
                      <span>Phytclean Certificate</span>
                    </v-tooltip>
                  </template>
                </v-data-table>

              </v-card-text>
            </v-col>
          </v-expand-transition>
          <transition name="slide-nav-right">
            <v-col cols="12" sm="3" md="2" style="height: 90vh;" v-if="viewExportCertificateInfo">
              <v-card flat outlined style="height: 100%">
                <v-card-title>
                  <v-btn text @click="viewExportCertificateInfo = false"><v-icon>chevron_right</v-icon></v-btn>
                  Export Certificate
                </v-card-title>
                <v-card-text>
                  <v-tabs flat grow v-model="exportCertificateTab">
                    <v-tabs-slider></v-tabs-slider>
                    <v-tab>Info</v-tab>
                    <v-tab> Certficates</v-tab>
                  </v-tabs>
                  <div v-if="exportCertificateTab == 0">
                    <v-list dense v-if="addendum && addendum.exportCertificate">
                      <v-list-item>
                        <v-list-item-action>
                          <v-icon>article</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ addendum.exportCertificate.fileNumber }}
                          </v-list-item-title>
                          <v-list-item-subtitle>File Number</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-action>
                          <v-icon>location_on</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ addendum.exportCertificate.portOfLoadName }}
                          </v-list-item-title>
                          <v-list-item-subtitle>Place of Issue</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-subheader style="font-size: 16px">Exporter Address</v-subheader>
                      <span>
                        {{ addendum.exportCertificate.shipperAddress }}
                      </span>
                      <v-divider class="mt-2"></v-divider>
                      <v-subheader style="font-size: 16px">Summary</v-subheader>
                      <v-divider></v-divider>
                      <v-list-item
                        v-if="addendum && addendum.exportCertificate && addendum.exportCertificate.palletSummary">
                        <v-list-item-action>
                          <v-icon color="blue">scale</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            Total Weight
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{
                              addendum.exportCertificate.palletSummary.reduce((a, b) => a + parseFloat(b.nettWeight),
                                0).toFixed(2)
                            }} KG
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list dense style="max-height: 20vh; overflow-y: auto"
                        v-if="addendum && addendum.exportCertificate && addendum.exportCertificate.palletSummary">
                        <v-list-item v-for="(pallet, palletIndex) in addendum.exportCertificate.palletSummary"
                          :key="palletIndex">
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ pallet.product }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <v-icon left small>tag</v-icon> {{ pallet.cartons }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                              <v-icon left small>grade</v-icon> {{ pallet.class }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                              <v-icon left small>scale</v-icon>{{ pallet.nettWeight }} KG
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item></v-list>





                    </v-list>
                    <v-list dense :key="taskActionKey" v-if="task.status == 'In Progress' || task.status == 'Approved'"
                      style="max-height: 30vh; overflow-y:auto">
                      <v-subheader style="font-size: 16px">Task Actions</v-subheader>
                      <v-progress-linear indeterminate color="primary" v-if="loadingTaskActions"></v-progress-linear>
                      <v-list-item @click="executeAction(action)" v-for="action in taskActions" :key="action.code">
                        <v-list-item-action>
                          <v-icon small :color="action.color">{{
                            action.icon
                          }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title class="text-wrap">
                            {{ action.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle class="text-wrap">
                            {{ action.description }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action v-show="loadingAction && loadingAction == action.code
                          ">
                          <v-progress-circular size="24" indeterminate color="primary"></v-progress-circular>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item v-if="!loadingTaskActions && taskActions.length == 0">
                        <v-list-item-content class="text-center">
                          <span style="color: grey">No actions available</span>
                        </v-list-item-content></v-list-item>
                    </v-list>
                  </div>
                  <div v-else>

                    <v-list dense style="max-height: 70vh; min-height: 10vh; overflow-y: auto;"
                      :key="inspectionDocumentKey">
                      <v-progress-linear v-if="loadingInspectionDocuments" indeterminate
                        color="primary"></v-progress-linear>
                      <v-list-item v-for="doc in inspectionDocuments" :key="doc.id">
                        <v-list-item-action class="ml-0 pl-0" style="width: 20px">
                          <v-img v-if="getDocumentIcon(doc.name)" class="mr-2" style="height: 30px; width: 30px"
                            :src="getDocumentIcon(doc.name)">
                          </v-img>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title class="text-wrap">
                            {{ doc.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ formatDate(doc.createdAt) }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action :key="documentKey">
                          <v-row :key="inspectionDocumentKey">
                            <v-btn :key="inspectionDocumentKey" :loading="docLoading == doc.id" icon
                              v-if="doc.name && (doc.name.toLowerCase()).includes('.pdf')" small
                              @click="previewInspectionDocument(doc)"><v-icon small>visibility</v-icon></v-btn>
                            <v-btn icon :loading="docDownloading == doc.id" @click="downloadInspectionDocument(doc)"
                              small><v-icon small>download</v-icon></v-btn>
                          </v-row>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item v-if="!loadingInspectionDocuments &&
                        inspectionDocuments.length == 0
                        ">
                        <v-list-item-content class="text-center">
                          <span style="color: grey">No attachments.</span>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>



                </v-card-text>
              </v-card>
            </v-col>
          </transition>

        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog v-model="consignmentNoteModal" :fullscreen="$vuetify.breakpoint.mobile || consignmentNoteFullscreen"
      width="1500px">
      <v-card flat>
        <v-toolbar flat style="background-color: var(--v-greyBase-base) !important">
          <v-toolbar-title style="font-size: 16px">
            {{ documentItem.sscc }} <span v-if="displayPage">(Detected on page {{ displayPage }})</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn v-if="documentItem.name" icon small @click="rotate += 90">
            <v-icon>rotate_right</v-icon>
          </v-btn>
          <v-btn v-if="documentItem.name" small icon @click="rotate -= 90">
            <v-icon>rotate_left</v-icon>
          </v-btn>
          <v-btn @click="consignmentNoteFullscreen = !consignmentNoteFullscreen" icon><v-icon
              v-if="!consignmentNoteFullscreen">fullscreen</v-icon><v-icon v-else>fullscreen_exit</v-icon></v-btn>
          <v-btn icon @click="downloadDocument(documentItem)"
            :loading="downloadingFile == documentItem.id"><v-icon>download</v-icon></v-btn>
          <v-btn v-if="documentItem.name" text @click="$refs.pdf[0].print()">
            <v-icon>print</v-icon>
          </v-btn>
          <v-btn icon
            @click="(consignmentNoteModal = false), (documentItem = {}), displayPage = null, consignmentNoteTab = 0">X</v-btn>
        </v-toolbar>
        <v-card-text v-if="documentItem && documentItem.pallet">
          <v-list dense>
            <v-row>
              <v-col cols="12" sm="2">
                <v-list-item style="height: 15px">
                  <v-list-item-content>
                    <v-list-item-title>
                      PUC
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action-text>
                    {{ documentItem.pallet.farm }}
                  </v-list-item-action-text>
                </v-list-item>
                <v-list-item style="height: 15px">
                  <v-list-item-content>
                    <v-list-item-title>
                      PHC
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action-text>
                    {{ documentItem.pallet.packHouse }}
                  </v-list-item-action-text>
                </v-list-item>
                <v-list-item style="height: 15px">
                  <v-list-item-content>
                    <v-list-item-title>
                      Orchard
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action-text>
                    {{ documentItem.pallet.orchard }}
                  </v-list-item-action-text>
                </v-list-item>


              </v-col>
              <v-col cols="12" sm="2">
                <v-list-item style="height: 15px">
                  <v-list-item-content>
                    <v-list-item-title>
                      Production Area
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action-text>
                    {{ documentItem.pallet.productionArea }}
                  </v-list-item-action-text>
                </v-list-item>
                <v-list-item style="height: 15px">
                  <v-list-item-content>
                    <v-list-item-title>
                      Phyto Data
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action-text>
                    {{ documentItem.pallet.phytoReference }}
                  </v-list-item-action-text>
                </v-list-item>

                <v-list-item style="height: 15px">
                  <v-list-item-content>
                    <v-list-item-title>
                      Container No.
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action-text>
                    {{ documentItem.pallet.containerNo }}
                  </v-list-item-action-text>
                </v-list-item>

              </v-col>
              <v-col cols="12" sm="2">
                <v-list-item style="height: 15px">
                  <v-list-item-content>
                    <v-list-item-title>
                      Commodity
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action-text>
                    {{ documentItem.pallet.commodity }}
                  </v-list-item-action-text>
                </v-list-item>
                <v-list-item style="height: 15px">
                  <v-list-item-content>
                    <v-list-item-title>
                      Variety
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action-text>
                    {{ documentItem.pallet.variety }}
                  </v-list-item-action-text>
                </v-list-item>
                <v-list-item style="height: 15px">
                  <v-list-item-content>
                    <v-list-item-title>
                      Flesh Colour
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action-text>
                    {{ documentItem.pallet.fleshColour }}
                  </v-list-item-action-text>
                </v-list-item>

              </v-col>
              <v-col cols="12" sm="2">
                <v-list-item style="height: 15px">
                  <v-list-item-content>
                    <v-list-item-title>
                      Count
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action-text>
                    {{ documentItem.pallet.countSize }}
                  </v-list-item-action-text>
                </v-list-item>
                <v-list-item style="height: 15px">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ documentItem.pallet.packType ?? 'Cartons' }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action-text>
                    {{ documentItem.pallet.noCartons }}
                  </v-list-item-action-text>
                </v-list-item>
                <v-list-item style="height: 15px">
                  <v-list-item-content>
                    <v-list-item-title>
                      Grade/Class
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action-text>
                    <span v-if="documentItem.pallet.gradeClassCode.includes(1)">
                      {{ 1 }}
                    </span>
                    <span v-else-if="documentItem.pallet.gradeClassCode.includes(2)">
                      {{ 2 }}
                    </span>
                    <span v-else-if="documentItem.pallet.gradeClassCode.includes('P')">
                      {{ 'P' }}
                    </span>
                  </v-list-item-action-text>
                </v-list-item>

              </v-col>
              <v-col cols="12" sm="2">
                <v-list-item style="height: 15px">
                  <v-list-item-content>
                    <v-list-item-title>
                      Manifest No
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action-text>
                    {{ documentItem.pallet.inspectionManifestNo }}
                  </v-list-item-action-text>
                </v-list-item>
                <v-list-item style="height: 15px">
                  <v-list-item-content>
                    <v-list-item-title>
                      Inspection Date
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action-text>
                    {{ documentItem.pallet.inspectionDate }}
                  </v-list-item-action-text>
                </v-list-item>
                <v-list-item style="height: 15px">
                  <v-list-item-content>
                    <v-list-item-title>
                      Inspection Point
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action-text>
                    {{ documentItem.pallet.inspectionPoint }}
                  </v-list-item-action-text>
                </v-list-item>
              </v-col>

              <v-col cols="12" sm="2">
                <v-list-item style="height: 15px">
                  <v-list-item-content>
                    <v-list-item-title>
                      Nett Weight
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action-text>
                    {{ documentItem.pallet.nettWeight }} KG
                  </v-list-item-action-text>
                </v-list-item>
                <v-list-item style="height: 15px">
                  <v-list-item-content>
                    <v-list-item-title>
                      Gross Weight
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action-text>
                    {{ documentItem.pallet.grossWeight }} KG
                  </v-list-item-action-text>
                </v-list-item>
                <v-list-item style="height: 15px">
                  <v-list-item-content>
                    <v-list-item-title>
                      Stuff Date
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action-text>
                    {{ documentItem.pallet.stuffDate }}
                  </v-list-item-action-text>
                </v-list-item>
                <!-- <v-list-item style="height: 15px">
              <v-list-item-content>
                <v-list-item-title>
                  Inspection Point
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action-text>
                {{ documentItem.pallet.inspectionPoint }}
              </v-list-item-action-text>
              </v-list-item> -->
              </v-col>


            </v-row>
          </v-list>

          <el-container :key="itemKey" v-loading="documentItem.loadingImage">
            <v-row justify="center">
              <v-col cols="12" md="9" lg="8" xl="8" class="text-center">
                <div style="height: 75vh; overflow-y: auto">
                  <div v-if="documentItem.fileType == 'application/pdf' && !documentItem.loadingImage">
                    <div v-if="displayPage">
                      <v-tabs v-model="consignmentNoteTab">
                        <v-tab>
                          Detected Page
                        </v-tab>
                        <v-tab>
                          Full Document
                        </v-tab>
                      </v-tabs>
                      <div v-if="consignmentNoteTab == 0" class="mt-1">
                        <!-- <pdf ref="pdf" :src="documentItem.fullFile" :rotate="rotate" :page="displayPage"
                          @page-loaded="displayPage = $event"></pdf> -->
                        <VuePdfEmbed :page="displayPage" :rotation="rotate" :width="fullscreen ? 1200 : 800"
                          :source="documentItem.url" />

                      </div>
                      <div v-else class="mt-1">
                        <!-- <pdf ref="pdf" :src="documentItem.fullFile" v-for="page in documentItem.pages" :key="page"
                          :rotate="rotate" :page="page"></pdf> -->
                        <VuePdfEmbed :rotation="rotate" :width="fullscreen ? 1200 : 800" :source="documentItem.url" />

                        <!-- <pdf style="display: none;" :src="documentItem.fullFile" @num-pages="pageCount = new Array($event)" />
                <pdf v-for="(page, index) in pageCount" :key="index" :src="documentItem.fullFile" :page="index + 1"
                  class="contract-container" /> -->
                      </div>


                    </div>
                    <div v-else>
                      <VuePdfEmbed :rotation="rotate" :width="fullscreen ? 1200 : 800" :source="documentItem.url" />

                      <!-- <pdf ref="pdf" :src="documentItem.fullFile" v-for="page in documentItem.pages" :key="page"
                        :rotate="rotate" :page="page"></pdf> -->
                      <!-- <pdf style="display: none;" :src="documentItem.fullFile" @num-pages="pageCount = new Array($event)" />
                <pdf v-for="(page, index) in pageCount" :key="index" :src="documentItem.fullFile" :page="index + 1"
                  class="contract-container" /> -->
                    </div>

                  </div>

                  <v-img v-else-if="!documentItem.loadingImage &&
                    documentItem.fileType &&
                    documentItem.fileType.includes('image')
                    " :style="{ transform: `rotate(${rotate}deg)` }" :src="documentItem.fullFile"></v-img>
                </div>
              </v-col>
            </v-row>
          </el-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="permitModal" :fullscreen="$vuetify.breakpoint.mobile" width="1200px">
      <v-card flat>
        <v-toolbar flat>
          <v-toolbar-title style="font-size: 16px">
            <!-- {{ documentItem.name }} -->
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn v-if="documentItem.name" icon small @click="rotate += 90">
            <v-icon>rotate_right</v-icon>
          </v-btn>
          <v-btn v-if="documentItem.name" small icon @click="rotate -= 90">
            <v-icon>rotate_left</v-icon>
          </v-btn>
          <v-btn icon @click="downloadDocument(documentItem)"
            :loading="downloadingFile == documentItem.id"><v-icon>download</v-icon></v-btn>
          <v-btn v-if="documentItem.name" text @click="$refs.pdf[0].print()">
            <v-icon>print</v-icon>
          </v-btn>
          <v-btn icon @click="(permitModal = false), (documentItem = {})">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <el-container>
            <el-main :key="itemKey" v-loading="documentItem.loadingImage">
              <div style="height: 75vh; overflow-y: auto">
                <div v-if="documentItem.fileType == 'application/pdf'">
                  <!-- <pdf ref="pdf" :src="documentItem.fullFile" v-for="page in documentItem.pages" :key="page"
                    :rotate="rotate" :page="page"></pdf> -->
                  <VuePdfEmbed :rotation="rotate" :width="fullscreen ? 1200 : 800" :source="documentItem.url" />

                </div>

                <v-img v-else-if="documentItem.fileType &&
                  documentItem.fileType.includes('image')
                  " :style="{ transform: `rotate(${rotate}deg)` }" :src="documentItem.fullFile"></v-img>
              </div>
            </el-main>
          </el-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="phytcleanModal" :fullscreen="$vuetify.breakpoint.mobile" width="1200px">
      <v-card flat>
        <v-toolbar flat>
          <v-toolbar-title style="font-size: 16px">
            {{ documentItem.fileType }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn v-if="documentItem.name" icon small @click="rotate += 90">
            <v-icon>rotate_right</v-icon>
          </v-btn>
          <v-btn v-if="documentItem.name" small icon @click="rotate -= 90">
            <v-icon>rotate_left</v-icon>
          </v-btn>

          <!-- <v-btn v-if="documentItem.name" text @click="$refs.pdf[0].print()">
            <v-icon>print</v-icon>
          </v-btn> -->
          <v-btn icon @click="(phytcleanModal = false), (documentItem = {})">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <el-container>
            <el-main :key="itemKey" v-loading="documentItem.loadingImage">
              <div style="height: 75vh; overflow-y: auto">
                <div v-if="documentItem.fileType && documentItem.fileType.includes('.pdf')">
                  <!-- <pdf ref="pdf" :src="documentItem.fullFile" :rotate="rotate"></pdf> -->
                  <VuePdfEmbed :rotation="rotate" :width="fullscreen ? 1200 : 800" :source="documentItem.url" />
                </div>

                <v-img v-else-if="documentItem.fileType &&
                  documentItem.fileType.includes('image')
                  " :style="{ transform: `rotate(${rotate}deg)` }" :src="documentItem.fullFile"></v-img>
              </div>
            </el-main>
          </el-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="previewModal" :fullscreen="$vuetify.breakpoint.mobile || fullscreen" width="1200px" persistent>
      <v-card flat v-if="previewItem && previewItem.fullFile">
        <v-toolbar flat color="transparent">
          <v-toolbar-title style="font-size: 16px">
            {{ previewItem.name }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="fullscreen = !fullscreen" icon><v-icon v-if="!fullscreen">fullscreen</v-icon><v-icon
              v-else>fullscreen_exit</v-icon></v-btn>
          <v-btn icon :key="downloadKey" :loading="downloadingFile == previewItem.id"
            @click="downloadDocument(previewItem)"><v-icon>download</v-icon></v-btn>

          <v-btn icon @click="(previewModal = false), (previewItem = {})">X</v-btn>
        </v-toolbar>
        <v-card-text :style="{
          'max-height': fullscreen ? '88vh' : '80vh',
          'overflow-y': 'auto',
          'background-color': 'var(--v-modal-base) !important',
        }">
          <v-row justify="center">
            <v-col cols="12" md="9" lg="8" xl="8" class="text-center">
              <div ref="pdfBox">
                <pdf style="display: none;" :src="previewItem.fullFile" @num-pages="pageCount = new Array($event)" />
                <pdf v-for="(page, index) in pageCount" :key="index" :src="previewItem.fullFile" :page="index + 1" />
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="rejectionReasonModal" width="750px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            Rejection Reason
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="rejectionReasonModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-textarea outlined placeholder="Please leave some comments on the reasons the inspection has been rejected."
            v-model="inspectionResult.comments"></v-textarea>
          <v-row class="mt-1" justify="center">
            <v-col cols="12" class="text-center">
              <v-btn color="primary" rounded small :disabled="!inspectionResult.comments" :loading="savingInspection"
                @click="submitInspectionResult">
                Submit Inspection 
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="revokeModal" width="750px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            Revoking Inspection
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="revokeModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text v-if="inspectionResult.inspectionData">
          <v-text-field outlined v-model="revokeObj.revokedComment" label="Revoke Comment"></v-text-field>
          <v-row class="mt-1" justify="center">
            <v-col cols="12" class="text-center">
              <v-btn color="primary" rounded small :loading="savingInspection" :disabled="!revokeObj.revokedComment"
                @click="revokeStatus">
                Revoke Inspection
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="certificateNumberModal" width="750px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            Approving Status
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="certificateNumberModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text v-if="inspectionResult.inspectionData">
          <v-text-field outlined v-model="inspectionResult.inspectionData.exportCertificateNumber"
            label="Certificate Number"></v-text-field>
          <v-text-field outlined v-model="inspectionResult.comments" label="Additional Comments"></v-text-field>
          <v-row class="mt-1" justify="center">
            <v-col cols="12" class="text-center">
              <v-btn color="primary" rounded small :loading="savingInspection"
                :disabled="!inspectionResult.inspectionData.exportCertificateNumber" @click="submitInspectionResult">
                Submit Inspection 3
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="notesModal" width="400px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Notes</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="notesModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <p>{{ task.notes }}</p>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="uniqueSSCCModal" persistent width="400px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-card-title>
          Unique SSCC's <v-chip small class="ml-1">{{ uniquePallets.length }}</v-chip>
          <v-spacer></v-spacer>
          <v-btn text @click="uniqueSSCCModal = false, searchUniquePallet = null">X</v-btn>
        </v-card-title>
        <v-card-text>
          <v-text-field label="Search" prepend-inner-icon="search" outlined dense clearable
            v-model="searchUniquePallet"></v-text-field>
          <v-list dense style="max-height: 60vh; overflow-y: auto">
            <v-list-item style="height: 25px" v-for="sscc in filterUniquePallets" :key="sscc.index">
              <v-list-item-action style="width: 80px" class="text-center">
                <v-chip small>{{ sscc.index }}</v-chip>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ sscc.sscc }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="opendEditDialog" width="800px" height="800px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-card-title> Inspection Comment : Update
          <v-spacer></v-spacer>
          <v-btn text @click="opendEditDialog = false" style="color:red">X</v-btn>
        </v-card-title>
        <v-card-text>
          <v-text-field label="Comment" v-model="dialogHistory.comments"></v-text-field>
          <v-row class="mt-1" justify="center">
            <v-col cols="12" class="text-center">
              <v-btn color="primary" rounded small :loading="savingInspection" @click="updateInspectionComments">
                Update Comments
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>

      </v-card>
    </v-dialog>

    <v-dialog v-model="uploadModal" width="800px" height="800px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-card-title>Upload additional attachments
          <v-spacer></v-spacer>
          <v-btn text @click="uploadModal = false, fileList = []" style="color:red">X</v-btn>
        </v-card-title>
        <v-card-text>
          <span v-if="fileList.length == 0">Drop or
            <v-btn text class="mx-1 px-0" small color="blue" @click="addFiles()">Select Files</v-btn>
            to upload</span>
          <v-row justify="center" v-if="fileList.length > 0">
            <v-btn small color="blue" class="ma-1" @click="addFiles()">Select Files</v-btn>
            <v-btn @click="submitFiles()" small class="ma-1" color="green" :loading="uploadingFiles">Submit Upload</v-btn>
            <v-btn small class="ma-1" color="red" @click="fileList = []">Clear</v-btn>
          </v-row>
          <v-list v-if="fileList.length > 0" class="text-left">
            <v-list-item v-for="(file, i) in fileList" :key="file.id">
              <v-list-item-content>
                <v-list-item-title>
                  {{ file.file.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ file.file.type }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action :key="file.progress">
                <v-btn v-if="!file.progress || file.progress == 0" icon color="red" @click="fileList.splice(i, 1)">
                  <v-icon>close</v-icon>
                </v-btn>
                <span v-else-if="file.progress < 100">
                  {{ file.progress }}%
                </span>
                <span v-else-if="file.progress == 100">
                  <v-icon color="green" class="mr-1">check</v-icon>
                </span>
              </v-list-item-action>
              <v-list-item-action :key="file.upload">
                <span v-if="file.uploadDone === true">
                  <v-icon color="green"> check</v-icon>
                </span>
                <span v-else-if="file.uploadDone === false">
                  <v-icon color="red"> close</v-icon>
                </span>
                <!-- <span else-if="file.uploadDone === null">
                    <v-icon color="blue"> sync</v-icon>
                  </span> -->
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
      <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
        <h3>Drop files to upload</h3>
      </div>
      <file-upload class="my-0 py-0" post-action="" :multiple="true" :drop="true" :drop-directory="true"
        v-model="fileList" ref="uploader">
      </file-upload>
    </v-dialog>
    <v-layout row justify-center>
      <v-dialog v-model="confirmDialog" persistent max-width="290">
        <v-card>
          <v-alert :type="passValidation ? 'success' : 'error'"><v-spacer></v-spacer>{{passValidation ? 'APPROVE' : 'REJECT'}}<v-spacer></v-spacer></v-alert>
          <v-card-text>{{`Are you sure you want to ${ passValidation ? 'approve' : 'reject' } this inspection?`}}</v-card-text>
          <v-card-actions>
            <v-btn color="secondary" flat @click.native="submitInspection(task)">Yes</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="tersiary" flat @click.native="confirmDialog = false">No</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </div>
</template>
<script>
import axios from 'axios'
import dateFormat from 'dateformat';
import pdf from "vue-pdf";
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import FileUpload from "vue-upload-component";
export default {
  props: ['task'],
  components: {
    pdf,
    VuePdfEmbed,
    FileUpload
  },
  watch: {
    'task.id': {
      immediate: true,
      handler(val) {
        if (val) {
          // this.getAddendumData(val)
          this.tab = 0
          this.inspectionResult = {}
          this.inspectionHistory = []
          this.addendum = {}
          this.getInspectionResultData(val)
          this.viewTaskInfo = true
          this.getTaskActions()
        }
        this.viewExportCertificateInfo = true
      }
    }
  },
  data: () => ({
    dialogHistory: {
      comments: ''
    },
    opendEditDialog: false,
    fileList: [],
    uploadDone: null,
    actionKey: 6000,
    addendum: {},
    canSubmit: null,
    revokeModal: false,
    certificateNumberModal: false,
    consignmentNoteTab: 0,
    consignmentNoteModal: false,
    consignmentNoteFullscreen: true,
    customHeaders: ['noCartons', 'sscc'],
    displayPage: null,
    documentItem: {},
    docDownloading: null,
    docLoading: null,
    downloadingFile: null,
    downloadingDocument: false,
    downloadKey: 1000,
    documentKey: 2000,
    exportCertificateTab: 0,
    fields: [
      'etd',
      'portOfLoadCode',
      'portOfDischargeCode',
      'targetCountry',
      'targetRegion',
      'exporterName',
      'vesselName',
      'voyageNumber',
      'regimeCode',
      'userFullName',
      'sumCartons',
      'nettWeightSum',
      'totalPallets',
      'inspectionDate',
      'containerNo',
      'stuffDate',
      'inspectionManifestNo'
    ],
    fullscreen: false,
    itemKey: 0,
    loadingInspectionResult: false,
    loadingMarkAll: false,
    activeUsers: [],
    inspectionResult: {},
    inspectionResultKey: 3000,
    inspectionHistory: [],
    inspectionDocuments: [],
    inspectionDocumentKey: 8000,
    loading: false,
    loadingAction: null,
    loadingField: null,
    loadingInspectionDocuments: false,
    loadingTaskActions: false,
    notesModal: false,
    pageCount: [],
    permitModal: false,
    phytcleanModal: false,
    palletHeaders: [
      {
        text: "L",
        value: "line",
        sortable: false,
        width: "20px",
        align: "center",
        tooltip: 'Line'
      },
      {
        text: "Action",
        value: "action",
        sortable: false,
        width: "20px",
        align: "center",
      },
      {
        text: "Attach",
        value: "attachments",
        sortable: false,
        align: "center",
      },
      {
        text: "PUC(s)",
        value: "farm",
        sortable: false,
        align: "center",
      },
      {
        text: "Orchard",
        value: "orchard",
        sortable: false,
        align: "center",
      },
      {
        text: "Phyto Data",
        value: "phytoReference",
        sortable: false,
        align: "center",
      },
      {
        text: "PHC",
        value: "packHouse",
        sortable: false,
        align: "center",
      },
      {
        text: "Prod. Area",
        value: "productionArea",
        sortable: false,
        align: "center",
      },
      {
        text: "Com",
        value: "commodity",
        sortable: false,
        align: "center",
      },
      {
        text: "Variety",
        value: "variety",
        sortable: false,
        align: "center",
      },
      {
        text: "FC",
        value: "fleshColour",
        sortable: false,
        align: "center",
      },
      {
        text: "CG",
        value: "gradeClassCode",
        width: "20px",
        sortable: false,
        align: "center",
      },
      // {
      //   text: "Count",
      //   value: "countSize",
      //   width: "20px",
      //   sortable: false,
      //   align: "center",
      // },
      {
        text: "NC",
        value: "noCartons",
        sortable: false,
        width: "20px",
        align: "right",
      },
      {
        text: "Container No.",
        value: "containerNo",
        sortable: false,
        width: "20px"
      },
      {
        text: "Nett Weight",
        value: "nettWeight",
        sortable: false,
        align: "right",
      },
      {
        text: "Pallet ID",
        value: "sscc",
        sortable: false,
        align: "center",
      },
      {
        text: "PPECB Inspection Date",
        value: "inspectionDate",
        width: "250px",
        sortable: false,
        align: "center",
      },
      {
        text: "Stuff Date",
        value: "stuffDate",
        width: "100px",
        sortable: false,
        align: "center",
      },
      {
        text: "Manifest No.",
        value: "inspectionManifestNo",
        sortable: false,
        align: "center",
      },
      {
        text: "IP",
        value: "inspectionPoint",
        width: "20px",
        sortable: false,
        align: "center",
      },
    ],
    previewItem: {},
    previewModal: false,
    rejectionReasonModal: false,
    rotate: 0,
    savingInspection: false,
    searchUniquePallet: null,
    tab: 0,
    taskAttachmentLoading: false,
    taskAttachments: [],
    taskActions: [],
    taskActionKey: 7000,
    taskAttachmentKey: 6000,
    uniqueSSCCModal: false,
    validationKey: 5000,
    viewTaskInfo: true,
    viewExportCertificateInfo: false,
    tableKey: 9000,
    revokeObj: {
      taskId: null,
      // revokedComment: null,
      // status: null
      revoked: null
    },
    uploadModal: false,
    uploadingFiles: false,
    confirmDialog: false
  }),
  created() {
    console.log('inspection result', this.inspectionResult)
  },
  computed: {
    passValidation() {
      let data = this.inspectionResult && this.inspectionResult.taskInspectionResultFields ? this.inspectionResult.taskInspectionResultFields : []
      let result = false
      if (data && data.length > 0) {
        let findPassedFields = data.filter(x => x.status == true)
        for (let i = 0; i < this.fields.length; i++) {
          let find = findPassedFields.find(x => x.field == this.fields[i])
          if (!find) {
            result = false
            break
          } else {
            result = true
          }
        }
        // console.log('Field check', result)
        if (result && this.addendum && this.addendum.auditLines && this.addendum.auditLines.length > 0) {
          // let pallets = this.addendum.pallet.filter((x, index) => this.addendum.auditLines.includes(index + 1))
          // for (let i = 0; i < pallets.length; i++) {
          //   let find = findPassedFields.find(x => x.field == pallets[i].sscc)
          //   if (!find) {
          //     result = false
          //     break
          //   } else {
          //     result = true
          //   }
          // }
          let pallets = this.addendum.pallet.filter(x => x.audit)
          for (let i = 0; i < pallets.length; i++) {
            let pallet = pallets[i]
            if (pallet.audit) {
              // console.log('Audit line', i, pallet.sscc)

              let find = this.inspectionResult.taskInspectionResultFields.find(x => x.field == pallet.sscc)
              if (find && find.status != true) {
                // console.log('Failed pallet', pallet.sscc)
                result = false
                break
              } else {
                result = true
              }
              //   if (find.status != true) {
              //     find.status = true
              //     find.time = new Date()
              //     let index = this.inspectionResult.taskInspectionResultFields.findIndex(x => x.field == pallet.sscc)
              //     array[index] = find
              //   }

              // } else {
              //   array.push({
              //     field: pallet.sscc,
              //     status: true,
              //     time: new Date(),
              //     taskInspectionResultId: this.inspectionResult.id
              //   })
              // }
            }
          }
        }
      }
      return result
    },
    filterUniquePallets() {
      let result = this.uniquePallets.map((x, i) => ({
        index: i + 1,
        sscc: x
      }))
      if (this.searchUniquePallet) {
        result = result.filter(x => x.sscc.includes(this.searchUniquePallet))
      }
      return result
    },
    uniquePallets() {
      let result = []
      if (this.addendum && this.addendum.pallet) {
        result = [...new Set(this.addendum.pallet.map((x) => x.sscc))]
      }
      return result
    },
    validateInspection() {
      let data = this.inspectionResult && this.inspectionResult.taskInspectionResultFields ? this.inspectionResult.taskInspectionResultFields : []
      let result = false
      if (data && data.length > 0) {
        let findPassedFields = data.filter(x => x.status != null)
        for (let i = 0; i < this.fields.length; i++) {
          let find = findPassedFields.find(x => x.field == this.fields[i])
          if (!find) {
            result = false
            break
          } else {
            result = true
          }
        }
        if (result && this.addendum && this.addendum.auditLines && this.addendum.auditLines.length > 0) {
          let pallets = this.addendum.pallet.filter(x => x.audit)
          for (let i = 0; i < pallets.length; i++) {
            let pallet = pallets[i]
            if (pallet.audit) {
              // if (this.isAuditLine(this.addendum.pallet[i], i)) {

              // console.log('Audit line', i, pallet.sscc)

              let find = this.inspectionResult.taskInspectionResultFields.find(x => x.field == pallet.sscc)
              if (!find) {
                result = false
                break
              }
              //   if (find.status != true) {
              //     find.status = true
              //     find.time = new Date()
              //     let index = this.inspectionResult.taskInspectionResultFields.findIndex(x => x.field == pallet.sscc)
              //     array[index] = find
              //   }

              // } else {
              //   array.push({
              //     field: pallet.sscc,
              //     status: true,
              //     time: new Date(),
              //     taskInspectionResultId: this.inspectionResult.id
              //   })
              // }
            }
          }
          // let pallets = this.addendum.pallet.filter((x, index) => this.addendum.auditLines.includes(index + 1))
          // let palletCheck = pallets.every(x => data.map(y => y.field).includes(x.sscc))

          // if (palletCheck) {
          //   result = true
          // } else {
          //   result = false
          // }
        }
      }
      return result
    }
  },
  methods: {

    updateInspectionComments() {
      this.$API.updateInspectionComments(this.dialogHistory)
      this.opendEditDialog = false
      this.getInspectionHistory(this.task.id)
    },
    openEditDialog(history) {
      this.opendEditDialog = true
      this.dialogHistory = history
    },
    addFiles() {
      this.$refs.uploader.$el.children[0].click();
    },
    async submitFiles() {
      let inspectionResult = await this.$API.getInspectionResultData(this.task.id);
      for (let i = 0; i < this.fileList.length; i++) {
        let url = await URL.createObjectURL(this.fileList[i].file);
        console.log('inspection Result', inspectionResult)
        this.toDataUrl(url, async (data) => {
          console.log('tasks ', this.task)
          let obj = {
            name: this.fileList[i].name,
            data: data,
            type: this.fileList[i].type,
            size: this.fileList[i].size,
            shipmentId: this.task.shipmentId,
            customerId: '2507',
            supplierId: '2507',
            id: this.task.shipmentDocumentId,
            taskId: inspectionResult.originalTaskId,
          };
          await this.submitUpload(obj);
          this.getTaskAttachments(inspectionResult.originalTaskId)
        });
      }
      console.log('inspection return', inspectionResult)


    },
    async submitUpload(obj) {
      try {
        this.uploadingFiles = true;
        let res = await this.$API.roboDanieUploadShippingDocument(obj);
        console.log('res', res)
        if (res.status == 'success') {
          this.$message({
            message: "File Uploaded Successfully",
            type: "success",
          });
          this.uploadingFiles = false;
        }
      } catch (err) {
        console.log(err);

      }
    },

    toDataUrl(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    },

    async changeStatus(field) {
      if (this.task.status == 'In Progress' && this.loadingField != field) {
        this.loadingField = field
        let find = this.inspectionResult.taskInspectionResultFields.find(x => x.field == field)
        if (find) {
          if (find.status == null) {
            find.status = true
            find.time = new Date()
            await this.$API.updateInspectionField(find)
          } else if (find.status == true) {
            find.status = false
            find.time = new Date()
            await this.$API.updateInspectionField(find)
          } else {
            find.status = null
            find.time = new Date()
            await this.$API.updateInspectionField(find)
          }
        } else {
          let result = await this.$API.createInspectionField({
            field: field,
            status: true,
            time: new Date(),
            taskInspectionResultId: this.inspectionResult.id
          })
          this.inspectionResult.taskInspectionResultFields.push(result)
        }
        await this.$API.updateInspectionResult({
          id: this.inspectionResult.id,
          inspectedById: this.$store.state.user.id
        })
        this.loadingField = null
        this.validationKey++
      }
    },

    async executeAction(action) {
      console.log('executeAction', action)
      console.log('pallet', this.addendum)
      let productType = ''
      if (action.code == 'ZA_PPECB_GenerateAddendum') {
        let productIds = this.addendum.pallet.map(x => x.productId)
        let uniqueProductId = [...new Set(productIds)]
        console.log('uniqueProductId', uniqueProductId)
        let productGroups = await this.$API.getProductGroupByProduct({ productIds: uniqueProductId })
        console.log('productGroups', productGroups)
        if (productGroups.status == 200) {
          productType = productGroups.name
        }
      }


      this.loadingAction = action.code;
      this.taskActionKey++
      if (action.code != 'ZA_PPECB_GeneratePhytClean') {
        let result = await this.$API.executeInspection(
          action.code,
          this.task.id,
          this.inspectionResult.id,
          { ...this.task.payload, ...this.task.responseData, productType: productType }
        );
        console.log('result', result)
        if (result.key) {
          await this.downloadDocument(result)
        }
      }
      else if (action.code == 'ZA_PPECB_GeneratePhytClean') {
        let result = await this.$API.excecutePPECBPhytClean(
          action.code,
          this.task.id,
          this.inspectionResult.id,
          { ...this.task.payload, ...this.task.responseData }
        );
        // console.log('result',result)
        await this.getInspectionResultData(this.task.id)

      }
      this.loadingAction = null
      this.taskActionKey++
      this.getInspectionDocuments(this.inspectionResult.id)
    },
    async fieldStatus(field) {
      let find = null
      if (this.inspectionResult && this.inspectionResult.taskInspectionResultFields) {
        find = this.inspectionResult.taskInspectionResultFields.find(x => x.field == field)
      }
      let value = null
      if (find) {
        value = find.status
      }
      switch (value) {
        case true:
          return { color: 'green', icon: 'task_alt' }
        case false:
          return { color: 'red', icon: 'cancel' }
        default:
          return { color: 'orange', icon: 'error' }
      }
    },
    fieldFriendlyName(field) {
      switch (field) {
        case 'etd':
          return 'Departure Date'
        case 'portOfLoadCode':
          return 'Port of Load'
        case 'portOfDischargeCode':
          return 'Port of Entry'
        case 'targetCountry':
          return 'Target Country'
        case 'containerNo':
          return 'Container No.'
        case 'targetRegion':
          return 'Target Region'
        case 'vesselName':
          return 'Vessel'
        case 'voyageNumber':
          return 'Voyage Number'
        case 'regimeCode':
          return 'Regime Code'
        case 'exporterName':
          return 'Exporter Name'
        case 'inspectionDate':
          return 'Inspection Date'
        case 'userFullName':
          return 'User Name'
        case 'totalPallets':
          return 'Total Pallets'
        case 'nettWeightSum':
          return 'Nett Weight'
        case 'sumCartons':
          return 'Total Cartons'
        case 'stuffDate':
          return 'Stuff Date'
        case 'inspectionManifestNo':
          return 'Manifest No.'
        default:
          return field
      }
    },
    fieldStatusColor(field) {
      let find = null
      if (this.inspectionResult && this.inspectionResult.taskInspectionResultFields) {
        find = this.inspectionResult.taskInspectionResultFields.find(x => x.field == field)
      }
      let value = null
      if (find) {
        value = find.status
      }
      switch (value) {
        case true:
          return 'green'
        case false:
          return 'red'
        default:
          return 'orange'
      }
    },
    fieldStatusIcon(field) {
      let find = null
      if (this.inspectionResult && this.inspectionResult.taskInspectionResultFields) {
        find = this.inspectionResult.taskInspectionResultFields.find(x => x.field == field)
      }
      let value = null
      if (find) {
        value = find.status
      }
      switch (value) {
        case true:
          return 'task_alt'
        case false:
          return 'cancel'
        default:
          return 'error'
      }
    },
    customHeader(header) {
      switch (header.value) {
        case 'noCartons':
          return 'No. of Cartons<br/> ' + this.addendum.sumOfCartons
        case 'sscc':
          return 'Pallet ID <br/> ' + this.addendum.totalPallets
      }

    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(date, "dd/mm/yyyy HH:MM");
      }
      return result;
    },
    getDocumentIcon(name) {
      let type = name.split(".");
      let result = null;
      if (name.includes(".") && type.length > 0) {
        result = type.at(-1);
      }
      if (result == "xlsx") {
        result = "xls";
      }
      return result ? `/icons/${result}.svg` : null;
    },
    getIcon(name) {
      if (name) {
        let type = name.split(".");
        let result = null;
        if (type.length > 0) {
          result = type.at(-1).toLowerCase();
        }
        if (result && result.toLowerCase() == "xlsx") {
          result = "xls";
        }
        return `/icons/${result}.svg`;
      }
      return null

    },
    async getInspectionResultData(id) {
      this.loadingInspectionResult = true
      this.loading = true
      this.inspectionResult = await this.$API.getInspectionResultData(id)
      let inspectionData = await axios.get(this.inspectionResult.inspectionDataURL)
      this.addendum = inspectionData.data
      this.inspectionResult.inspectionData = this.addendum
      if (this.addendum.pallet && this.addendum.pallet[0]) {
        let keys = Object.keys(this.addendum.pallet[0])
        if (!keys.includes('audit')) {
          await this.addendum.pallet.forEach((x, i) => {
            x.audit = this.isAuditLine(x, i)

          }
          )

        }
        setTimeout(() => {
          this.$socket.send(
            JSON.stringify({
              action: "join",
              room: "ppecb" + this.task.id,
              user: {
                id: this.$store.state.user.id,
                name:
                  this.$store.state.user.firstname +
                  " " +
                  this.$store.state.user.surname,
                avatar: this.$store.state.user.avatar,
                company: this.$store.state.currentOrg.name,
              },
            })
          );
        }, 1000);
        this.$socket.onopen = () => {
          this.$socket.send(
            JSON.stringify({
              action: "join",
              room: "ppecb_" + this.task.id,
              user: {
                id: this.$store.state.user.id,
                name:
                  this.$store.state.user.firstname +
                  " " +
                  this.$store.state.user.surname,
                avatar: this.$store.state.user.avatar,
                company: this.$store.state.currentOrg.name,
              },
            })
          );
        };
        this.$socket.onmessage = (data) => {
          let message = JSON.parse(data.data);
          if (this.task && this.task.id) {
            if (
              message.type == "users" &&
              message.room == "ppecb" + this.task.id
            ) {
              this.activeUsers = message.data;
              this.userUpdateKey++;
            }
          }
        };
      }
      this.canSubmit = this.inspectionResult.id
      this.loading = false
      this.getTaskAttachments(this.inspectionResult.originalTaskId)
      this.getInspectionDocuments(this.inspectionResult.id)

      this.inspectionHistory = await this.$API.getAllInspectionResults(id)
      this.loadingInspectionResult = false
      this.inspectionResultKey++
    },
    async getInspectionDocuments(id) {
      this.inspectionDocumentKey++
      this.loadingInspectionDocuments = true
      this.inspectionDocuments = await this.$API.getInspectionResultDocuments(id)
      this.loadingInspectionDocuments = false
      this.inspectionDocumentKey++
    },
    async getSpecificInspectionResult(id) {
      this.loadingInspectionResult = true
      this.loading = true
      this.inspectionResult = await this.$API.getInspectionResult(id)
      this.addendum = this.inspectionResult.inspectionData
      this.getTaskAttachments(this.inspectionResult.originalTaskId)
      this.loading = false
      this.loadingInspectionResult = false
    },
    async getTaskAttachments(id) {
      try {
        console.log('task attachmment id', id)
        this.taskAttachmentLoading = true
        this.taskAttachments = await this.$API.getInspectionAttachments(id)
        this.taskAttachmentLoading = false
        this.taskAttachmentKey++
      } catch (error) {
        console.log(error)
      }
    },
    getTaskStatus(task) {
      switch (task.status) {
        case "Pending":
          return { name: task.status, icon: "schedule", color: "orange" };
        case "In Progress":
          return { name: task.status, icon: "pending", color: "blue" };
        case 'Resubmitted':
          return { name: task.status, icon: "sync", color: "orange" };
        case "Rejected":
          return { name: task.status, icon: "cancel", color: "red" };
        case "Complete":
          return { name: task.status, icon: "task_alt", color: "green" };
        case "Success":
          return { name: task.status, icon: "task_alt", color: "green" };
        case "Cancelled":
          return { name: task.status, icon: "cancel", color: "grey" };
        case "Error":
          return { name: task.status, icon: "error", color: "red" };
        case "Not Created":
          return { name: task.status, icon: "error", color: "red" };
        case "Failed":
          return { name: task.status, icon: "cancel", color: "red" };
        case "File Generated":
          return { name: task.status, icon: "task_alt", color: "orange" };
        case "Draft":
          return { name: task.status, icon: "edit_note", color: "orange" };
        case "Submitted":
          return { name: task.status, icon: "mark_email_read", color: "blue" };
        case "Not Required":
          return { name: task.status, icon: "close", color: "grey" };
        case "Approved":
          return { name: task.status, icon: "fact_check", color: "green" };
        default:
          return {
            icon: "info",
            color: "grey",
            name: task.status,
          };
      }
    },
    itemRowBackground(item) {
      // let index = this.addendum.pallet.findIndex(x => x.id == item.id)
      // // console.log('Audit Lines', this.addendum.auditLines, index + 1, item, this.addendum.pallet)
      // if (this.isAuditLine(item, index)) {
      //   return 'myclass'
      // }
      if (item.audit) {
        return 'myclass'
      }
    },
    async markAll() {
      if (this.task.status == 'In Progress') {
        this.loadingMarkAll = true
        let array = []
        // console.log(this.fields)
        for (let i = 0; i < this.fields.length; i++) {
          let field = this.fields[i]
          this.loadingField = field
          let find = this.inspectionResult.taskInspectionResultFields.find(x => x.field == field)
          if (find) {
            if (find.status != true) {
              find.status = true
              find.time = new Date()
              let index = this.inspectionResult.taskInspectionResultFields.findIndex(x => x.field == field)
              // console.log('Setting index', index)
              array[index] = find
            }

          } else {
            array.push({
              field: field,
              status: true,
              time: new Date(),
              taskInspectionResultId: this.inspectionResult.id
            })
          }

          this.loadingField = null
          this.validationKey++
        }
        if (this.addendum && this.addendum.auditLines && this.addendum.auditLines.length > 0) {
          // let pallets = this.addendum.pallet.filter((x, index) => this.addendum.auditLines.includes(index + 1))
          let pallets = this.addendum.pallet.filter(x => x.audit)
          for (let i = 0; i < pallets.length; i++) {
            let pallet = pallets[i]
            if (pallet.audit) {
              // if (this.isAuditLine(this.addendum.pallet[i], i)) {

              // console.log('Audit line', i, pallet.sscc)

              let find = this.inspectionResult.taskInspectionResultFields.find(x => x.field == pallet.sscc)
              if (find) {
                if (find.status != true) {
                  find.status = true
                  find.time = new Date()
                  let index = this.inspectionResult.taskInspectionResultFields.findIndex(x => x.field == pallet.sscc)
                  array[index] = find
                }

              } else {
                array.push({
                  field: pallet.sscc,
                  status: true,
                  time: new Date(),
                  taskInspectionResultId: this.inspectionResult.id
                })
              }
            }
          }
          // for (let i = 0; i < pallets.length; i++) {
          //   let pallet = pallets[i]
          //   let find = this.inspectionResult.taskInspectionResultFields.find(x => x.field == pallet.sscc)
          //   if (find) {
          //     if (find.status != true) {
          //       find.status = true
          //       find.time = new Date()
          //       let index = this.inspectionResult.taskInspectionResultFields.findIndex(x => x.field == pallet.sscc)
          //       array[index] = find
          //     }

          //   } else {
          //     array.push({
          //       field: pallet.sscc,
          //       status: true,
          //       time: new Date(),
          //       taskInspectionResultId: this.inspectionResult.id
          //     })
          //   }
          // }
        }
        array = array.filter(x => x)

        let result = await this.$API.createInspectionField({
          batch: array
        })
        this.inspectionResult.taskInspectionResultFields = result
        await this.$API.updateInspectionResult({
          id: this.inspectionResult.id,
          inspectedById: this.$store.state.user.id
        })
        this.validationKey++

        this.loadingMarkAll = false

      }
    },
    async previewInspectionDocument(doc) {
      this.docLoading = doc.id;
      this.documentKey++
      this.previewItem = doc
      if (!this.previewItem) {
        this.previewItem = {}
      }
      let file = await this.$API.downloadFile({
        key: doc.key
      });
      this.previewItem.fullFile = file.file;
      this.previewItem.fileType = file.fileType;
      this.previewModal = true
      this.docLoading = null
      this.documentKey++
    },
    async previewDocument(doc) {
      this.docLoading = doc.id;
      this.documentKey++
      this.previewItem = doc
      if (!this.previewItem) {
        this.previewItem = {}
      }
      let file = await this.$API.downloadFile({
        key: doc.shipmentDocument.key
      });
      this.previewItem.fullFile = file.file;
      this.previewItem.fileType = file.fileType;
      this.previewModal = true
      this.docLoading = null
      this.documentKey++
    },
    submitInspection(task) {
      this.confirmDialog = false
      if (!this.passValidation) {
        this.rejectionReasonModal = true
      } else {
        this.certificateNumberModal = true
      }
    },
    revokeInspection() {
      this.revokeModal = true
    },
    async revokeStatus() {
      this.savingInspection = true
      this.revokeObj.status = 'Revoked'
      this.revokeObj.taskId = this.task.id
      this.revokeObj.revoked = true
      this.revokeObj.shipmentId = this.task.shipmentId
      this.revokeObj.shipmentDocumentTypeId = this.task.shipmentDocumentTypeId
      this.revokeObj.parentShipmentDocumentId = this.task.parentShipmentDocumentId
      console.log('this.task', this.task)
      // this.revokeObj.revokedComment = this.revokeComment
      let res = await this.$API.revokeInspection(this.revokeObj)
      // console.log('res',res)
      this.savingInspection = false
      this.revokeObj = {}
      this.closeAddendumDialog()
      this.$emit('update', this.task)
      this.$emit('close')
      this.$message.success("Successfully revoke inspection!")
    },
    async submitInspectionResult() {
      this.savingInspection = true
      this.task.status = this.passValidation ? 'Approved' : 'Rejected'
      this.inspectionResult.status = this.task.status
      this.rejectionReasonModal = false
      this.certificateNumberModal = false
      this.inspectionResult.end = new Date()
      this.inspectionResult.inspectedById = this.$store.state.user.id
      this.task.inspectedById = this.$store.state.user.id
      if (this.inspectionResult && this.inspectionResult.inspectionData && this.inspectionResult.inspectionData.exportCertificateNumber) {
        this.inspectionResult.reference = this.inspectionResult.inspectionData.exportCertificateNumber
        this.task.reference = this.inspectionResult.inspectionData.exportCertificateNumber
      }
      if (this.inspectionResult && this.inspectionResult.inspectionData) {
        for (let i = 0; i < this.inspectionResult.inspectionData.pallet.length; i++) {
          // let pallet = this.inspectionResult.inspectionData.pallet[i]
          if (this.inspectionResult.inspectionData.pallet[i].consignmentNote && this.inspectionResult.inspectionData.pallet[i].consignmentNote.fullFile) {
            delete this.inspectionResult.inspectionData.pallet[i].consignmentNote.pallet
            delete this.inspectionResult.inspectionData.pallet[i].consignmentNote.fullFile
          }

          if (this.inspectionResult.inspectionData.pallet[i].permitDocument && this.inspectionResult.inspectionData.pallet[i].permitDocument.fullFile) {
            delete this.inspectionResult.inspectionData.pallet[i].permitDocument.pallet
            delete this.inspectionResult.inspectionData.pallet[i].permitDocument.fullFile
          }
          // if(pallet.phytcleanCertificate && pallet.phytcleanCertificate.fullFile){
          //   delete pallet.phytcleanCertificate.fullFile
          // }
        }
      }
      await this.$API.submitFinalInspectionResult(this.inspectionResult)
      await this.$API.updateInspectionTask(this.task)
      this.savingInspection = false
      if (this.task.status == 'Approved' && this.inspectionResult.inspectionData.exportCertificateNumber) {
        let findAction = this.taskActions.find(x => x.code == 'ZA_PPECB_GenerateAddendum')
        await this.executeAction(findAction)
        if (this.addendum.breakbulk) {
          let findSSCCAction = this.taskActions.find(x => x.code == 'ZA_PPECB_GenerateSSCCIndexList')
          await this.executeAction(findSSCCAction)
        }
        let exportCertAction = this.taskActions.find(x => x.code == 'ZA_PPECB_ExportCertificate')
        await this.executeAction(exportCertAction)
      }
      this.closeAddendumDialog()
      this.$emit('update', this.task)
      this.$emit('close')
      this.$message.success("Successfully processed inspection!")
    },
    async downloadDocument(doc) {
      this.downloadingFile = doc.id
      this.docDownloading = doc.id;
      this.downloadKey++;
      if (doc.url) {
        var hiddenElement = document.createElement("a");
        hiddenElement.href = doc.url;
        hiddenElement.target = "_blank";
        hiddenElement.download = doc.name;
        hiddenElement.click();
      } else {
        let key = doc.file ? doc.file : doc.key ? doc.key : doc.shipmentDocument.key
        if (!doc.fullFile) {
          let file = await this.$API.downloadFile({
            key: key
          });
          doc.fullFile = file.file;
          doc.fileType = file.fileType;
        }

        let element = document.createElement("a");
        element.href = doc.fullFile;
        element.target = "_blank";
        element.download = doc.name ? doc.name : doc.shipmentDocument && doc.shipmentDocument.name ? doc.shipmentDocument.name : 'Document';
        element.click();
      }

      this.downloadingFile = null;
      this.docDownloading = null
      this.downloadKey++;
    },
    async downloadInspectionDocument(doc) {
      this.downloadingFile = doc.id
      this.docDownloading = doc.id;
      this.downloadKey++;
      let file = await this.$API.downloadFile({
        key: doc.key
      });
      doc.fullFile = file.file;
      doc.fileType = file.fileType;
      var hiddenElement = document.createElement("a");
      hiddenElement.href = doc.fullFile;
      hiddenElement.target = "_blank";
      hiddenElement.download = doc.name;
      hiddenElement.click();
      this.downloadingFile = null;
      this.docDownloading = null
      this.downloadKey++;
    },
    async startTask(task) {
      this.task.status = 'In Progress'
      this.task.startedById = this.$store.state.user.id
      this.task.startedBy = {
        id: this.$store.state.user.id,
        firstName: this.$store.state.user.firstName,
        surname: this.$store.state.user.surname,
        emailAddress: this.$store.state.user.emailAddress
      }
      this.tableKey++
      await this.$API.updateTask(task)
      this.inspectionResult.status = 'In Progress'
      this.inspectionResult.start = new Date()
      this.inspectionResult.startedById = this.$store.state.user.id
      this.inspectionResult.startedBy = {
        id: this.$store.state.user.id,
        firstName: this.$store.state.user.firstName,
        surname: this.$store.state.user.surname,
        emailAddress: this.$store.state.user.emailAddress
      }
      this.inspectionHistory.push(this.inspectionResult)
      await this.$API.updateInspectionResult({ id: this.inspectionResult.id, status: 'In Progress', startedById: this.$store.state.user.id, start: new Date() })
    },
    async getTaskActions() {
      if(this.task.id){
        this.loadingTaskActions = true;
      this.taskActions = []
      this.taskActions = await this.$API.getTaskActions(this.task.id);
      //add phytClean to task actions
      let shipmentId = this.task.shipmentId
      let phytCleanId = await this.$API.getPhytCleanActionData(shipmentId)
      // console.log('taskactions',this.taskActions)
      console.log('phytId', phytCleanId)
      if (phytCleanId) {
        let res = {
          code: 'ZA_PPECB_GeneratePhytClean',
          name: 'Regenerate PhytClean',
          color: 'blue',
          icon: 'assignment'
        }
        this.taskActions = [...this.taskActions, res]
      }
      this.actionKey++;
      this.loadingTaskActions = false;
      }
      
    },
    isAuditLine(item, index) {
      // return item.audit
      if (!this.addendum.breakbulk) {
        let containers = [...new Set(this.addendum.pallet.map(x => x.containerNo))]
        if (containers.length > 1) {
          let palletIndex = this.addendum.pallet.filter(x => x.containerNo == item.containerNo).findIndex(x => x.id == item.id)
          return this.addendum.auditLines.includes(palletIndex + 1)

        } else {
          return this.addendum.auditLines.includes(index + 1)
        }
      } else {
        let uniquePallets = [...new Set(this.addendum.pallet.map((x) => x.sscc))]

        let totalPallets = uniquePallets.length
        let auditLine = this.addendum.auditLines[0]
        let indices = []
        let run = true
        let count = 0
        while (run) {
          let setIndex = null
          if (indices.length == 0) {
            setIndex = auditLine
          } else {
            setIndex = (count * 50) + 50 + auditLine
            count++
          }
          if (setIndex >= totalPallets) {
            setIndex = totalPallets
            indices.push(setIndex)
            run = false
          } else {
            let findIndex = uniquePallets[setIndex - 1]
            findIndex = this.addendum.pallet.filter(x => x.sscc == findIndex)
            let lastId = findIndex[findIndex.length - 1]
            let finalIndex = this.addendum.pallet.findIndex(x => x.id == lastId.id)
            indices.push(finalIndex)
          }
        }
        // console.log('Audited pallets', this.addendum.auditLines)
        // for (let j = 1; j <= auditedPallets; j++) {
        //   let itemIndex = (j * auditLine)
        //   if (j == 1 && itemIndex > totalPallets) {
        //     if (itemIndex > totalPallets) {
        //       itemIndex = totalPallets
        //     }
        //   }
        //   if (j > 1) {
        //     itemIndex = (auditLine + ((j - 1) * 50))
        //   }
        //   let findPallet = uniquePallets[itemIndex - 1]
        //   if (findPallet) {
        //     // console.log('Audit pallet', uniquePallets[itemIndex - 1], itemIndex, uniquePallets)
        //     itemIndex = this.addendum.pallet.map((x) => x.sscc).lastIndexOf(findPallet)
        //     itemIndex += 1
        //     if (itemIndex <= totalPallets) {
        //       indices.push(itemIndex)
        //     }
        //   }
        //   if(!indices.includes(totalPallets)){
        //     indices.push(totalPallets)
        //   }
        // }
        // console.log(indices)
        return indices.includes(index + 1)
      }

    },
    async viewConsignmentNote(item) {
      this.documentItem = item.consignmentNote;
      // console.log(item.consignmentNote)
      this.documentItem.pallet = { ...item }
      delete this.documentItem.pallet.consignmentNote
      this.documentItem.sscc = item.sscc;
      this.documentItem.loadingImage = true;
      this.consignmentNoteModal = true;
      this.documentItem.url = await this.$API.getDocumentLink({
        id: item.consignmentNote.id,
        key: item.consignmentNote.key,
      })
      // let file = await this.$API.downloadFile({
      //   key: item.consignmentNote.key,
      // });
      this.displayPage = item.consignmentNotePage ?? null
      // console.log("View Page", this.displayPage)
      // this.documentItem.name = item.sscc;
      // this.documentItem.fullFile = file.file;
      this.documentItem.fileType = this.documentItem.type;
      console.log('Document', this.documentItem)
      this.documentItem.loadingImage = false;
      this.itemKey++;
    },
    async viewRemovalPermit(item) {
      this.documentItem = item.permitDocument;
      this.documentItem.loadingImage = true;
      this.permitModal = true;
      // let file = await this.$API.downloadFile({
      //   key: item.permitDocument.key,
      // });
      this.documentItem.name = item.permitDocument.name;
      // this.documentItem.fullFile = file.file;
      this.documentItem.fileType = item.permitDocument.type;
      this.documentItem.url = await this.$API.getDocumentLink({
        id: item.permitDocument.id,
        key: item.permitDocument.key,
      })
      this.documentItem.loadingImage = false;
      this.itemKey++;
    },
    async viewPhytcleanCertificate(item) {
      console.log('item', item)
      this.documentItem = {};
      this.documentItem.loadingImage = true;
      this.phytcleanModal = true;
      // let file = await this.$API.downloadFile({
      //   key: item.phytcleanCertificate,
      // });

      // this.documentItem.name = item.phytcleanCertificate.split('/').at(-1);
      // this.documentItem.fullFile = file.file;
      this.documentItem.url = await this.$API.getDocumentLink({
        key: item.phytcleanCertificate,
      })
      console.log('url', this.documentItem.url)
      this.documentItem.fileType = item.phytcleanCertificate.split('/').at(-1);
      console.log('doc type', this.documentItem.fileType)
      this.documentItem.loadingImage = false;
      this.itemKey++;
    },
    viewInspectionResult(item) {
      this.getSpecificInspectionResult(item.id)
    },
    closeAddendumDialog() {
      this.addendum = {}
      this.inspectionHistory = []
      this.$emit('close')
    }
  }
}
</script>
<style lang="css">

.infoBtn {
  position: absolute;
  height: 5vh;
  padding: 0.5vh 0 0.5vh 1vw;
  border-bottom-right-radius: 16px;
  border-top-right-radius: 16px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  background-color: white !important;
  color: white !important;
  width: 4vw;
  z-index: 2;
  top: 6vh;
  left: 0vw;
}

.myclass {
  color: orange !important;
  /* background-color: orange !important; */
  /* color: orange !important; */

}

.slide-nav-leave-active {
  transition: 0.5s;
}

.slide-nav-enter-active {
  transition: 0.8s;
}

.slide-nav-enter {
  transform: translateX(-100%);
}

.slide-nav-leave-to {
  transform: translateX(-100%);
}



.slide-marker-leave-active {
  transition: 0.1s;
}

.slide-marker-enter-active {
  transition: 1s;
}

.slide-marker-enter {
  transform: translateX(-100%);
}

.slide-marker-leave-to {
  transform: translateX(-100%);
}

.slide-nav-right-leave-active {
  transition: 0s;
}

.slide-nav-right-enter-active {
  transition: 0.8s;
}

.slide-nav-right-enter {
  transform: translateX(100%);
}

.slide-nav-right-leave-to {
  transform: translateX(100%);
}
</style>